import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import Web3 from "web3";
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");
const web3 = new Web3();
var theUser = "";
var thePass = "";

var i;
var txt;
var speed = 25;

function typeWriter() {
	if (i < txt.length) {
		document.getElementById("text").innerHTML += txt.charAt(i);
		i++;
		setTimeout(typeWriter, speed);
	} else {
		setTimeout(function () {
			window.$(".text-copy-area button").fadeIn(2000);
			window.$(".text-copy-area div").hide();
		}, 500);
	}
}

function generateProof() {
	window.$(".text-copy-area").show();
	window.$(".text-copy-area div").show();
	window.$(".text-copy-area button").hide();
	window.$("#proofButton").prop("disabled", true);
	window.$("#proofButton").html("Generating...");

	document.getElementById("text").innerHTML = "";
	var theArray = document.getElementById("data").value.split("\n");
	var newArray = [];

	theArray.forEach((element) => {
		try {
			newArray.push(web3.utils.toChecksumAddress(element));
		} catch (ex) {
			console.log(ex);
		}
	});
	var username = theUser;
	var password = thePass;

	axios.post(`https://proof.iteck.pk:2053/posthashes`, { data: newArray, username: username, password: password }).then((res) => {
		if (res.data.indexOf("Good") > -1) {
			const root = res.data.split(",")[1];

			i = 0;
			txt = root;
			typeWriter();
			window.$("#proofButton").prop("disabled", false);
			window.$("#proofButton").html("Generate Proof");
		} else {
			document.getElementById("text").innerHTML = "Wrong password";
			window.$("#proofButton").prop("disabled", false);
			window.$("#proofButton").html("Generate Proof");
		}
	});
}

function calculateValues() {
	var theArray = document.getElementById("data").value.split("\n").length;
	document.getElementById("walletCount").innerHTML = "[" + theArray + "]";
}

function loginUser() {
	document.getElementById("textAuthenticate").innerHTML = "";

	window.$("#loginButton").prop("disabled", true);
	window.$("#loginButton").html("Please wait...");

	var username = document.getElementById("username").value;
	var password = document.getElementById("password").value;

	axios.get(`https://proof.iteck.pk:2053/authenticate?username=` + username + `&password=` + password).then((res) => {
		if (res.data == "Good") {
			theUser = username;
			thePass = password;
			window.$(".text-copy-area").hide();
			window.$("#stepsSlider").carousel("next");
			window.$("#clientLogo").attr("src", "assets/img/logos/" + theUser + ".png");
		} else {
			document.getElementById("textAuthenticate").innerHTML = "Wrong password";
			window.$("#loginButton").prop("disabled", false);
			window.$("#loginButton").html("Login");
		}
	});
}

function App() {
	return (
		<div className="main-content">
			<div className="row">
				<div className="col-lg-6 d-none d-lg-flex side-image">
					<div>
						<img data-src="assets/img/gif/background.gif" alt="" className="lazy" />
					</div>
				</div>
				<div className="col-lg-6">
					<div className="content">
						<div className="content-inner">
							<h1 className="main-title">Cryptographic Smart Proof</h1>
							<p className="text-primary lead">Gasless Whitelisting Dapp</p>
							<img style={{ maxWidth: "50%" }} id="clientLogo" src="" />

							<div id="stepsSlider" className="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
								<div className="carousel-inner">
									<div className="carousel-item active">
										<div className="step step-1">
											<div className="form-group form-s1">
												<img src="assets/img/svg/user.svg" alt="" className="icon" />
												<input id="username" type="text" className="form-control" placeholder="Username" />
											</div>
											<div className="form-group form-s1">
												<img src="assets/img/svg/lock.svg" alt="" className="icon" />
												<input id="password" type="password" className="form-control" placeholder="Password" />
											</div>
											<div className="mt-5">
												<p id="textAuthenticate" style={{ color: "red" }}></p>
												<button id="loginButton" onClick={loginUser} className="btn btn-primary rounded-pill btn-submit">
													Login
												</button>
											</div>
										</div>
									</div>
									<div className="carousel-item">
										<div className="step step-2">
											<div className="wallet active">
												<div className="wallet-inner">
													<p id="walletText">
														<img className="me-2" src="assets/img/svg/wallet.svg" alt="" /> Wallets <span id="walletCount">[0]</span>
													</p>
													<div className="form-group mb-0">
														<textarea onChange={calculateValues} id="data" className="form-control" rows="6"></textarea>
													</div>
												</div>
												<div style={{ display: "inline" }} className="text-copy-area">
													<p style={{ wordBreak: "break-word", display: "contents" }} id="text">
														Aliquet at at aliquet est, in. Purus dictum nisl.
													</p>
													<div className="blink"></div>
													<button style={{ display: "none" }}>
														<img src="assets/img/svg/copy.svg" alt="" />
													</button>
												</div>
											</div>
											<div className="mt-5">
												<button id="proofButton" onClick={generateProof} className="btn btn-primary btn-generate rounded-pill">
													Generate Proof
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p className="footer">Powered by Avolox</p>
					</div>
				</div>
				{/*}<div className="col-lg-6 d-none">
                <div className="content">
                    <div className="content-inner">
                        <h1 className="main-title">Cryptographic Smart Proof</h1>
                        <p className="text-primary lead">Gasless Whitelisting Dapp</p>
                        <div className="wallet active">
                            <div className="wallet-inner">
                                <p><img className="me-2" src="assets/img/svg/wallet.svg" alt=""/> Wallet</p>
                                <div className="form-group">
                                    <textarea id="data" className="form-control" rows="6">Bibendum ac malesuada et hendrerit enim augue aliquet habitasse non. Suspendisse euismod amet aliquet at at aliquet est, in. Purus dictum nisl eleifend vel diam.</textarea>
                                </div>
                            </div>
                            <div className="text-success text-success d-flex align-items-center">
                                <p className="mb-0"></p>
                                <button className="ms-2 border-0 bg-transparent p-0">
                                    <img src="assets/img/svg/copy.svg" alt=""/>
                                </button>
                            </div>
                        </div>
                        <div className="mt-5">
                            <button onClick={generateProof} className="btn btn-primary rounded-pill">Generate Proof-</button>
                        </div>
                    </div>
                    <p className="footer">Powered by Avolox</p>
                </div>
  </div>{*/}
			</div>
			<div className="alert alert-success" role="alert">
				Address has been copied!
			</div>
		</div>
	);
}

export default App;
